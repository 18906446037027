<template>
 <popup-modal ref="popup">
  <TransitionRoot as="template" :show="open">
   <Dialog as="div" class="fixed z-40 inset-0 overflow-y-auto" @close="_cancel">
    <div
     class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <DialogOverlay
       class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
     </TransitionChild>

     <!-- This element is to trick the browser into centering the modal contents. -->
     <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
     >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
      <div
       class="sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all">
       <div class="sm:flex sm:items-start">
        <div
         class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-500 sm:mx-0 sm:h-10 sm:w-10">
         <font-awesome-icon
          v-if="isSchedule"
          :icon="['far', 'calendar-check']"
          class="h-5 w-5 text-gray-100"
          aria-hidden="true" />
         <font-awesome-icon
          v-else
          icon="share"
          class="h-5 w-5 text-gray-100"
          aria-hidden="true" />
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
         <div class="pb-2 text-lg font-medium text-gray-900 space-y-1">
          <p class="">{{ title }}</p>
          <input
           type="text"
           name="report"
           v-model="nickname"
           class="sm:text-sm border-gray-300 rounded-md h-8"
           :placeholder="$t('pbxReport.nicknamePlaceholder')" />
         </div>
         <div class="my-2 text-gray-500 space-y-4">
          <!-- <p class="text-gray-500">Vous voulez partager ce rapport</p> -->
          <div class="font-medium text-gray-800">
           <!-- <li class="capitalize">
            {{ $t(reportTypeTitle) }}
           </li> -->

           <div v-if="isSchedule" class="pt-2 flex gap-2 items-center">
            <div>
             <label for="schedule" class="block">Type de rapport : </label>
             <select
              id="schedule"
              name="schedule"
              class="p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              v-model="this.cron"
              required="">
              <option value="day">{{ $t("daily") }}</option>
              <option value="week">{{ $t("weekly") }}</option>
              <option value="month">{{ $t("monthly") }}</option>
             </select>
            </div>
            <span class="mt-6"> à compter du </span>
            <input
             v-model="startAt"
             type="date"
             class="mt-6 p-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
           </div>
           <div v-else>
            Periode du rapport :
            {{
             periodSelector
              ? parseDateRange(periodSelector)
              : toLocaleDateString(startDate) +
                (" - " + toLocaleDateString(endDate))
            }}{{ time ? pbxTimeSelectedParsed : "" }}
           </div>
           <!-- <li v-else class="capitalize">
            {{ timeOption ? timeOption + ", " : "" }}
            {{
             periodSelector
              ? parseDateRange(periodSelector)
              : toLocaleDateString(startDate) +
                (" - " + toLocaleDateString(endDate))
            }}{{ time ? pbxTimeSelectedParsed : "" }}
           </li> -->
           <div class="capitalize" v-if="pbxElements">
            <div class="flex flex-wrap gap-px">
             {{
              elementOption === "queue"
               ? $t("sidebar.specialNavigation.callqueue")
               : elementOption
             }}
             <span class="px-0.5"> : </span>
             <span
              class="mr-1"
              v-for="(selection, index) in this.pbxElements"
              :key="selection">
              {{ selection }}{{ index === pbxElements.length - 1 ? "" : "," }}
             </span>
            </div>
           </div>
          </div>
          <div v-if="isSchedule">
           <!-- <SwitchGroup as="div" class="flex items-center mb-2">
            <Switch
             v-model="instant"
             :class="[
              instant ? 'body__header--button' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 encom_ring_color',
             ]">
             <span
              aria-hidden="true"
              :class="[
               instant ? 'translate-x-5' : 'translate-x-0',
               'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
             <span class="text-sm font-medium">
              {{ $t("Envoyez le rapport maintenant.") }}</span
             >
            </SwitchLabel>
           </SwitchGroup> -->
           <p class="">
            Vous recevrez votre premier rapport à la date que vous avez indiquée
            ci-dessus. Par exemple, pour un rapport mensuel, si vous choisissez
            le 1er janvier, vous recevrez chaque 1er du mois le rapport du mois
            précédent.
           </p>
          </div>
          <p class="text-sm text-gray-500 pt-2">
           {{
            $t(
             "Le rapport vous sera envoyé par défaut. Si vous le souhaitez, vous pouvez ajouter d'autres destinataires."
            )
           }}
          </p>
         </div>
         <form
          class="mt-0 sm:flex sm:max-w-md lg:mt-0"
          @submit.prevent="pushToEmails(email)">
          <div>
           <label for="email-address" class="sr-only">Email address</label>
           <div class="relative">
            <input
             v-model="email"
             type="email"
             name="email-address"
             id="email-address"
             autocomplete="email"
             required
             :class="[
              this.invalidEmail
               ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
               : 'border-gray-300 placeholder-gray-400 ',
              'shadow-sm block w-full sm:text-sm rounded-md',
             ]"
             placeholder="you@example.com" />
            <div
             v-if="this.invalidEmail"
             class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
             <ExclamationCircleIcon
              class="h-5 w-5 text-red-500"
              aria-hidden="true" />
            </div>
           </div>
           <p
            v-if="this.invalidEmail"
            class="text-sm text-red-600"
            id="email-error">
            {{
             !this.emailRegexError
              ? $t("pbxReport.emailRegexError")
              : $t("pbxReport.emailAlreadyExistError")
            }}
           </p>
          </div>
          <div class="mt-0 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
           <button
            type="submit"
            class="w-full border hover:bg-gray-100 border-gray-600 rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium">
            {{ $t("Ajouter un destinataire") }}
           </button>
          </div>
         </form>
        </div>
        <div
         @click="$emit('reportList'), this.$refs.popup.close()"
         class="mx-auto text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full encom_primary encom_primary_hover sm:mx-0 sm:h-10 sm:w-10 cursor-pointer">
         <font-awesome-icon icon="eye" class="h-5 w-5" aria-hidden="true" />
        </div>
       </div>
       <div class="py-1" v-show="emails.length > 0">
        <div v-for="email in emails" :key="email" class="inline-flex mb-1">
         <div class="mx-1">
          <span
           class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-600">
           {{ email }}
           <button
            @click="removeEmailFromList(email)"
            type="button"
            class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-700 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white">
            <span class="sr-only">Remove option</span>
            <svg
             class="h-2 w-2"
             stroke="currentColor"
             fill="none"
             viewBox="0 0 8 8">
             <path
              stroke-linecap="round"
              stroke-width="1.5"
              d="M1 1l6 6m0-6L1 7" />
            </svg>
           </button>
          </span>
         </div>
        </div>
       </div>
       <div class="pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
         type="button"
         class="capitalize w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 encom_primary encom_primary_hover text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm"
         @click="_confirm">
         {{ $t("validate") }}
        </button>
        <button
         type="button"
         class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
         @click="_cancel"
         ref="cancelButtonRef">
         {{ $t("ticket.cancel") }}
        </button>
       </div>
      </div>
     </TransitionChild>
    </div>
   </Dialog>
  </TransitionRoot>
 </popup-modal>
</template>

<script>
import PopupModal from "../PopupModal.vue";
import axios from "axios";
import { ref } from "vue";
import {
 Dialog,
 DialogOverlay,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const timezone = localStorage.getItem("hostTimeZone");

export default {
 props: [
  "elementOption",
  "parseDateRange",
  "parseTimeRange",
  "periodSelector",
  "selectHostName",
  "selectHostContext",
  "selections",
  "timeOption",
  "time",
  "startDate",
  "endDate",
  "urlType",
 ],
 setup() {
  const open = ref(true);

  return {
   open,
  };
 },
 data() {
  return {
   cron: "",
   dataset: "",
   emails: [],
   email: "",
   invalidEmail: false,
   emailRegexError: false,
   emailAlreadyExistError: false,
   message: undefined, // Main text content
   nickname: "",
   title: undefined,
   isSchedule: false,
   hour: "",
   timezone,
   subMessage: undefined, // text content before input
   instant: false,
   startAt: new Date(),
  };
 },
 components: {
  ExclamationCircleIcon,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  CheckIcon,
  PopupModal,
  Switch,
  SwitchGroup,
  SwitchLabel,
 },
 methods: {
  setUpDataSet() {
   if (this.$route.name === "ExpertStatistics") {
    this.dataset = "origins";
   } else if (this.$route.name === "ExpertStatisticsOriginDetail") {
    this.dataset = "originDetails";
   } else if (this.$route.name === "ExpertStatisticsAnsweredUnanswered") {
    this.dataset = "answered";
   } else if (this.$route.name === "ExpertStatisticsAnsweredDaily") {
    this.dataset = "answeredDaily";
   } else if (
    this.$route.name === "ExpertStatisticsAnsweredUnanswered" &&
    this.selectHostContext === "0"
   ) {
    this.dataset = "answeredInCallQueues";
   } else if (this.$route.name === "ExpertStatisticsQueueDetail") {
    this.dataset = "queueDetails";
   } else if (this.$route.name === "ExpertStatisticsFullQueueReport") {
    this.dataset = "report";
   } else if (this.$route.name === "ExpertStatisticsQueueComparator") {
    this.dataset = "queuesComparison";
   } else if (
    this.$route.name === "Dashboard" ||
    this.$route.name === "DashboardExpertStatistics"
   ) {
    this.dataset = "dashboard";
   } else if (this.$route.name === "ExpertStatisticsDIDReport") {
    this.dataset = "didReport";
   }
   this.setUpParams();
  },
  setUpParams() {
   let hour = undefined;
   let parameters = {};
   if (this.pbxTimeSelected) {
    hour = this.pbxTimeSelected[0] + "-" + this.pbxTimeSelected[1];
   } else {
    hour = null;
   }
   if (
    this.pbxElements &&
    this.pbxElements.length > 0 &&
    this.emails?.length > 0
   ) {
    let elements = this.pbxElements.toString();
    if (
     this.dataset === "queueDetails" ||
     this.dataset === "queuesComparison"
    ) {
     parameters = {
      pbx: this.selectHostName,
      elements: elements,
      type: "queue",
      time: hour,
      timezone: this.timezone,
      period: null,
      context: this.selectHostContext,
      dataSet: this.dataset,
     };
     this.postReport(parameters, "thank you");
    } else if (this.dataset === "report") {
     parameters = {
      pbx: this.selectHostName,
      elements: elements,
      type: "queue",
      time: hour,
      timezone: this.timezone,
      period: null,
      context: this.selectHostContext,
      dataSet: this.dataset,
     };
     this.postReport(parameters, "thank you");
    } else if (this.dataset === "dashboard") {
     parameters = {
      pbx: this.selectHostName,
      elements: elements,
      type: null,
      time: hour,
      timezone: this.timezone,
      period: null,
      context: this.selectHostContext,
      dataSet: this.dataset,
     };
     this.postReport(parameters, "thank you");
    } else if (this.dataset === "didReport") {
     parameters = {
      pbx: this.selectHostName,
      elements: elements,
      type: "queue",
      time: hour,
      timezone: this.timezone,
      period: null,
      context: this.selectHostContext,
      dataSet: this.dataset,
     };
     this.postReport(parameters, "thank you");
    } else {
     parameters = {
      pbx: this.selectHostName,
      elements: elements,
      type: this.elementOption,
      time: hour,
      timezone: this.timezone,
      period: null,
      context: this.selectHostContext,
      dataSet: this.dataset,
     };
     this.postReport(parameters, "thank you");
    }
   } else if (!this.elementOption && this.dataset === "dashboard") {
    parameters = {
     pbx: this.selectHostName,
     elements: "*",
     type: null,
     time: hour,
     timezone: this.timezone,
     period: null,
     context: this.selectHostContext,
     dataSet: this.dataset,
    };
    this.postReport(parameters, "thank you");
   } else {
    this.$emit("activeBanner");
    this.$emit("success", false);
    if (!this.emails || this.emails.length == 0) {
     this.$emit(
      "successMsg",
      "Veuillez ajouter au moins un destinataire d'e-mail."
     );
    } else {
     this.$emit("successMsg", "No element selected.");
    }
   }
  },
  postReport(parameters, msg) {
   let period = "";
   let start = "";
   let end = "";
   let timeStart = "";
   let timeEnd = "";
   let time = "";

   //  this.emails.indexOf(this.user.email) < 0
   //   ? this.emails.push(this.user.email)
   //   : "";

   //  if (parameters.time) {
   // time = parameters.time;
   timeStart = this.pbxTimeSelected[0];
   timeEnd = this.pbxTimeSelected[1];
   //  }

   period = parameters.period;

   if (period) {
    if (period.length == 6) {
     start = this.parseDateDayMonthYearHifen(
      this.getFirstDayOfMonth(period.slice(0, 4), period.slice(-2))
     );
     end = this.parseDateDayMonthYearHifen(
      this.getLastDayOfMonth(period.slice(0, 4), period.slice(-2))
     );
    }
    if (period.length == 8) {
     start = this.parseDateDayMonthYearHifen(
      new Date(
       period.slice(0, 4),
       parseInt(period.slice(4, 6)) - 1,
       parseInt(period.slice(-2))
      )
     );
     end = start;
    }
    if (period.includes("-")) {
     start = this.parseDateDayMonthYearHifen(
      new Date(
       period.split("-")[0].slice(0, 4),
       parseInt(period.split("-")[0].slice(4, 6)) - 1,
       parseInt(period.split("-")[0].slice(-2))
      )
     );
     end = this.parseDateDayMonthYearHifen(
      new Date(
       period.split("-")[1].slice(0, 4),
       parseInt(period.split("-")[1].slice(4, 6)) - 1,
       parseInt(period.split("-")[1].slice(-2))
      )
     );
    }
   }

   let message = this.isSchedule
    ? this.$t("pbxReport.plannedMsg")
    : this.$t("pbxReport.successMsg");

   if (
    this.$route.name === "Dashboard" ||
    this.$route.name === "DashboardExpertStatistics"
   ) {
    start = this.startDate;
    end = this.endDate;
    if (this.isSchedule) {
     start = this.formatDateHyphen(new Date(this.startAt));
    }
   }
   const options = {
    method: "POST",
    url: `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/reports`,
    data: {
     host_name: parameters.pbx,
     name: this.nickname
      ? this.nickname
      : `Rapport tableau de bord 3CX - ${
         this.isSchedule
          ? this.$t(this.getNameFromCron(this.cron))
          : this.startDate || this.endDate
          ? this.toLocaleDateString(this.startDate) +
            (" - " + this.toLocaleDateString(this.endDate))
          : this.cron
        }`,
     report_type: parameters.dataSet,
     element_type:
      this.elementOption === "queue"
       ? 4
       : this.elementOption === "extension"
       ? 0
       : this.elementOption === "did"
       ? "did"
       : "*",
     start_at: start + " " + timeStart,
     start: start + " " + timeStart,
     end: end + " " + timeEnd,
     email: this.emails.toString(),
     repeat: this.isSchedule,
     repeat_pattern: this.cron ? this.cron : null,
     dns: this.pbxElements ? this.pbxElements.toString() : "*",
     instant: this.instant,
     email_sender: this.user.email ? this.user.email : null,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
    },
   };

   axios
    .request(options)
    .then(res => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", message);
     this.cleanForm();
    })
    .catch(err => {
     this.errorHandling(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", err.response.data.response);
    });
  },
  cleanForm() {
   this.nickname = "";
   this.emails = [];
  },
  getNameFromCron(cron) {
   let name = "";
   switch (cron) {
    case "week":
     name = "weekly";
     break;
    case "month":
     name = "monthly";
     break;
    case "day":
     name = "daily";
     break;
   }
   return name;
  },
  show(opts = {}) {
   this.title = opts.title;
   this.message = opts.message;
   this.isSchedule = opts.isSchedule;
   this.okButton = opts.okButton;
   if (opts.cancelButton) {
    this.cancelButton = opts.cancelButton;
   }
   if (opts.action) {
    this.action = opts.action;
   }
   // Once we set our config, we tell the popup modal to open
   this.$refs.popup.open();
   // Return promise so the caller can get results
   return new Promise((resolve, reject) => {
    this.resolvePromise = resolve;
    this.rejectPromise = reject;
   });
  },
  _confirm() {
   try {
    this.setUpDataSet();
    this.$refs.popup.close();
    this.resolvePromise(true);
   } catch (error) {
    console.error(error);
   }
  },

  _cancel() {
   this.$refs.popup.close();
   this.resolvePromise(false);
   // Or you can throw an error
   // this.rejectPromise(new Error('User cancelled the dialogue'))
  },
  pushToEmails(email) {
   this.invalidEmail = false;
   this.emailRegexError = false;
   this.emailAlreadyExistError = false;

   if (this.validateEmail(email) && !this.emails.includes(email)) {
    this.emails.push(email);
    this.email = "";
   } else {
    this.invalidEmail = true;
    this.validateEmail(email)
     ? (this.emailRegexError = true)
     : (this.emailAlreadyExistError = true);
   }
  },
  removeEmailFromList(email) {
   let index = this.emails.indexOf(email);
   this.emails.splice(index, 1);
  },
  validateEmail(email) {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
  },
 },
 computed: {
  ...mapGetters([
   "pbxElements",
   "pbxTimeSelected",
   "pbxTimeSelectedEnd",
   "hostName",
   "user",
  ]),
  pbxTimeSelectedParsed() {
   if (this.time) {
    return this.pbxTimeSelected
     ? ", " + this.parseTimeRange(this.pbxTimeSelected)
     : "";
   }
  },
  reportTypeTitle() {
   return this.$route.name === "ExpertStatistics"
    ? "expertStatisticsNavigation.origins"
    : this.$route.name === "ExpertStatisticsAnsweredUnanswered"
    ? "expertStatisticsNavigation.answeredUnansweredCalls"
    : this.$route.name === "ExpertStatisticsOriginDetail"
    ? "expertStatisticsNavigation.originsTop10"
    : this.$route.name === "ExpertStatisticsFullQueueReport"
    ? this.$t("queueDetail")
    : this.$route.name === "ExpertStatisticsQueueComparator"
    ? "queueComparison"
    : this.$route.name === "ExpertStatisticsDIDReport"
    ? this.$t("didReport")
    : "dashboards.custom";
  },
 },
 mounted() {
  this.emails.indexOf(this.user.email) < 0
   ? this.emails.push(this.user.email)
   : "";
 },
 watch: {
  timeOption: function (val) {
   if (val === this.$t("dashboards.daily")) {
    this.cron = "day";
   } else if (val === this.$t("dashboards.weekly")) {
    this.cron = "week";
   } else {
    this.cron = "month";
   }
  },
 },
};
</script>

<style></style>
